@import url("https://fonts.googleapis.com/css?family=Cabin+Sketch:700");
@import url("https://fonts.googleapis.com/css?family=Muli");
@import url("https://fonts.googleapis.com/css?family=Great+Vibes");

@import "~uikit/src/scss/variables";

$global-font-cabin: "Cabin Sketch", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$global-font-muli: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$global-font-great: "Great Vibes", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$global-margin: 20px;
$accordion-item-margin-top: $global-margin;
$global-medium-font-size: 1.25rem;
$accordion-title-font-size: $global-medium-font-size;
$accordion-title-line-height: 1.4;
$global-emphasis-color: #666;
$accordion-title-color: $global-emphasis-color;
$global-color: #666;
$accordion-title-hover-color: $global-color;
$accordion-content-margin-top: $global-margin;
$global-inverse-color: #fff;
$inverse-global-emphasis-color: $global-inverse-color;
$inverse-accordion-title-color: $inverse-global-emphasis-color;
$inverse-global-color: rgba($global-inverse-color, 1);
$inverse-accordion-title-hover-color: $inverse-global-color;
$alert-margin-vertical: $global-margin;
$global-small-gutter: 15px;
$alert-padding: $global-small-gutter;
$alert-padding-right: $alert-padding + 14px;
$global-muted-background: #f8f8f8;
$alert-background: $global-muted-background;
$alert-color: $global-color;
$alert-close-top: $alert-padding + 5px;
$alert-close-right: $alert-padding;
$global-primary-background: #4ddbff;
$alert-primary-background: lighten(mix(white, $global-primary-background, 40%), 20%);
$alert-primary-color: $global-primary-background;
$global-success-background: #32d296;
$alert-success-background: lighten(mix(white, $global-success-background, 40%), 25%);
$alert-success-color: $global-success-background;
$global-warning-background: #faa05a;
$alert-warning-background: lighten(mix(white, $global-warning-background, 45%), 15%);
$alert-warning-color: $global-warning-background;
$global-danger-background: #f0506e;
$alert-danger-background: lighten(mix(white, $global-danger-background, 40%), 20%);
$alert-danger-color: $global-danger-background;
$global-gutter: 30px;
$align-margin-horizontal: $global-gutter;
$align-margin-vertical: $global-gutter;
$global-medium-gutter: 40px;
$align-margin-horizontal-l: $global-medium-gutter;
$animation-duration: 0.5s;
$animation-fade-duration: 0.8s;
$animation-kenburns-duration: 15s;
$animation-fast-duration: 0.1s;
$animation-slide-small-translate: 10px;
$animation-slide-medium-translate: 50px;
$global-large-margin: 70px;
$article-margin-top: $global-large-margin;
$global-xxlarge-font-size: 2.625rem;
$article-title-font-size-m: $global-xxlarge-font-size;
$article-title-font-size: $article-title-font-size-m * 0.85;
$article-title-line-height: 1.2;
$global-small-font-size: 0.875rem;
$article-meta-font-size: $global-small-font-size;
$article-meta-line-height: 1.4;
$global-muted-color: #333;
$article-meta-color: $global-muted-color;
$inverse-global-muted-color: rgba($global-inverse-color, 0.5);
$inverse-article-meta-color: $inverse-global-muted-color;
$global-background: #fff;
$background-default-background: $global-background;
$background-muted-background: $global-muted-background;
$background-primary-background: $global-primary-background;
$global-secondary-background: #222;
$background-secondary-background: $global-secondary-background;
$badge-size: 22px;
$badge-padding-vertical: 0;
$badge-padding-horizontal: 5px;
$badge-border-radius: 500px;
$badge-background: $global-primary-background;
$badge-color: $global-inverse-color;
$badge-font-size: $global-small-font-size;
$badge-hover-color: $global-inverse-color;
$inverse-global-primary-background: $global-inverse-color;
$inverse-badge-background: $inverse-global-primary-background;
$inverse-global-inverse-color: $global-color;
$inverse-badge-color: $inverse-global-inverse-color;
$inverse-badge-hover-color: $inverse-global-inverse-color;
$base-body-background: $global-background;
$global-font-family: $global-font-muli;
$base-body-font-family: $global-font-family;
$base-body-font-weight: normal;
$global-font-size: 20px;
$base-body-font-size: $global-font-size;
$global-line-height: 1.5;
$base-body-line-height: $global-line-height;
$base-body-color: $global-color;
$global-link-color: #4ddbff;
$base-link-color: $global-link-color;
$base-link-text-decoration: none;
$global-link-hover-color: #4ddbff;
$base-link-hover-color: $global-link-hover-color;
$base-link-hover-text-decoration: underline;
$base-strong-font-weight: bolder;
$base-code-font-size: $global-small-font-size;
$base-code-font-family: Consolas, monaco, monospace;
$base-code-color: $global-danger-background;
$base-em-color: $global-danger-background;
$base-ins-background: #ffd;
$base-ins-color: $global-color;
$base-mark-background: #ffd;
$base-mark-color: $global-color;
$base-quote-font-style: italic;
$base-small-font-size: 80%;
$base-margin-vertical: $global-margin;
$base-heading-font-family: $global-font-cabin;
$base-heading-font-weight: normal;
$base-heading-color: $global-emphasis-color;
$base-heading-text-transform: none;
$global-medium-margin: 40px;
$base-heading-margin-top: $global-medium-margin;
$base-h1-font-size-m: $global-xxlarge-font-size;
$base-h1-font-size: $base-h1-font-size-m * 0.85;
$base-h1-line-height: 1.2;
$global-xlarge-font-size: 2rem;
$base-h2-font-size-m: $global-xlarge-font-size;
$base-h2-font-size: $base-h2-font-size-m * 0.85;
$base-h2-line-height: 1.3;
$global-large-font-size: 1.5rem;
$base-h3-font-size: $global-large-font-size;
$base-h3-line-height: 1.4;
$base-h4-font-size: $global-medium-font-size;
$base-h4-line-height: 1.4;
$base-h5-font-size: $global-font-size;
$base-h5-line-height: 1.4;
$base-h6-font-size: $global-small-font-size;
$base-h6-line-height: 1.4;
$base-list-padding-left: 30px;
$base-hr-margin-vertical: $global-margin;
$global-border-width: 1px;
$base-hr-border-width: $global-border-width;
$global-border: #e5e5e5;
$base-hr-border: $global-border;
$base-blockquote-font-size: $global-medium-font-size;
$base-blockquote-line-height: 1.5;
$base-blockquote-font-style: italic;
$base-blockquote-margin-vertical: $global-margin;
$global-small-margin: 10px;
$base-blockquote-footer-margin-top: $global-small-margin;
$base-blockquote-footer-font-size: $global-small-font-size;
$base-blockquote-footer-line-height: 1.5;
$base-pre-font-size: $global-small-font-size;
$base-pre-line-height: 1.5;
$base-pre-font-family: $base-code-font-family;
$base-pre-color: $global-color;
$base-selection-background: #39f;
$base-selection-color: $global-inverse-color;
$inverse-base-color: $inverse-global-color;
$inverse-base-link-color: $inverse-global-emphasis-color;
$inverse-base-link-hover-color: $inverse-global-emphasis-color;
$inverse-base-code-color: $inverse-global-color;
$inverse-base-em-color: $inverse-global-emphasis-color;
$inverse-base-heading-color: $inverse-global-emphasis-color;
$inverse-global-border: rgba($global-inverse-color, 1);
$inverse-base-hr-border: $inverse-global-border;
$breadcrumb-item-font-size: $global-small-font-size;
$breadcrumb-item-color: $global-muted-color;
$breadcrumb-item-hover-color: $global-color;
$breadcrumb-item-hover-text-decoration: none;
$breadcrumb-item-active-color: $global-color;
$breadcrumb-divider: "/";
$breadcrumb-divider-margin-horizontal: 20px;
$breadcrumb-divider-color: $global-muted-color;
$inverse-breadcrumb-item-color: $inverse-global-muted-color;
$inverse-breadcrumb-item-hover-color: $inverse-global-color;
$inverse-breadcrumb-item-active-color: $inverse-global-color;
$inverse-breadcrumb-divider-color: $inverse-global-muted-color;
$global-control-height: 40px;
$button-line-height: $global-control-height;
$global-control-small-height: 30px;
$button-small-line-height: $global-control-small-height;
$global-control-large-height: 55px;
$button-large-line-height: $global-control-large-height;
$button-font-size: $global-font-size;
$button-small-font-size: $global-small-font-size;
$button-large-font-size: $global-medium-font-size;
$button-padding-horizontal: $global-gutter;
$button-small-padding-horizontal: $global-small-gutter;
$button-large-padding-horizontal: $global-medium-gutter;
$button-default-background: $global-muted-background;
$button-default-color: $global-emphasis-color;
$button-default-hover-background: darken($button-default-background, 5%);
$button-default-hover-color: $global-emphasis-color;
$button-default-active-background: darken($button-default-background, 10%);
$button-default-active-color: $global-emphasis-color;
$button-primary-background: $global-primary-background;
$button-primary-color: $global-inverse-color;
$button-primary-hover-background: darken($button-primary-background, 5%);
$button-primary-hover-color: $global-inverse-color;
$button-primary-active-background: darken($button-primary-background, 10%);
$button-primary-active-color: $global-inverse-color;
$button-secondary-background: $global-secondary-background;
$button-secondary-color: $global-inverse-color;
$button-secondary-hover-background: darken($button-secondary-background, 5%);
$button-secondary-hover-color: $global-inverse-color;
$button-secondary-active-background: darken($button-secondary-background, 10%);
$button-secondary-active-color: $global-inverse-color;
$button-danger-background: $global-danger-background;
$button-danger-color: $global-inverse-color;
$button-danger-hover-background: darken($button-danger-background, 5%);
$button-danger-hover-color: $global-inverse-color;
$button-danger-active-background: darken($button-danger-background, 10%);
$button-danger-active-color: $global-inverse-color;
$button-disabled-background: $global-muted-background;
$button-disabled-color: $global-muted-color;
$button-text-line-height: $global-line-height;
$button-text-color: $global-muted-color;
$button-text-hover-color: $global-color;
$button-text-disabled-color: $global-muted-color;
$button-link-line-height: $global-line-height;
$button-link-color: $global-link-color;
$button-link-hover-color: $global-link-hover-color;
$button-link-hover-text-decoration: underline;
$button-link-disabled-color: $global-muted-color;
$inverse-button-default-background: $inverse-global-primary-background;
$inverse-button-default-color: $inverse-global-inverse-color;
$inverse-button-default-hover-background: darken($inverse-button-default-background, 5%);
$inverse-button-default-hover-color: $inverse-global-inverse-color;
$inverse-button-default-active-background: darken($inverse-button-default-background, 10%);
$inverse-button-default-active-color: $inverse-global-inverse-color;
$inverse-button-primary-background: $inverse-global-primary-background;
$inverse-button-primary-color: $inverse-global-inverse-color;
$inverse-button-primary-hover-background: darken($inverse-button-primary-background, 5%);
$inverse-button-primary-hover-color: $inverse-global-inverse-color;
$inverse-button-primary-active-background: darken($inverse-button-primary-background, 10%);
$inverse-button-primary-active-color: $inverse-global-inverse-color;
$inverse-button-secondary-background: $inverse-global-primary-background;
$inverse-button-secondary-color: $inverse-global-inverse-color;
$inverse-button-secondary-hover-background: darken($inverse-button-secondary-background, 5%);
$inverse-button-secondary-hover-color: $inverse-global-inverse-color;
$inverse-button-secondary-active-background: darken($inverse-button-secondary-background, 10%);
$inverse-button-secondary-active-color: $inverse-global-inverse-color;
$inverse-button-text-color: #fff;
$inverse-button-text-hover-color: $inverse-global-color;
$inverse-button-text-disabled-color: $inverse-global-muted-color;
$inverse-button-link-color: $inverse-global-muted-color;
$inverse-button-link-hover-color: $inverse-global-color;
$card-body-padding-horizontal: $global-gutter;
$card-body-padding-vertical: $global-gutter;
$card-body-padding-horizontal-l: $global-medium-gutter;
$card-body-padding-vertical-l: $global-medium-gutter;
$card-header-padding-horizontal: $global-gutter;
$card-header-padding-vertical: round(calc($global-gutter / 2));
$card-header-padding-horizontal-l: $global-medium-gutter;
$card-header-padding-vertical-l: round(calc($global-gutter / 2));
$card-footer-padding-horizontal: $global-gutter;
$card-footer-padding-vertical: (calc($global-gutter / 2));
$card-footer-padding-horizontal-l: $global-medium-gutter;
$card-footer-padding-vertical-l: round(calc($global-gutter / 2));
$card-title-font-size: $global-large-font-size;
$card-title-line-height: 1.4;
$card-badge-top: $global-gutter;
$card-badge-right: $card-badge-top;
$card-hover-background: $global-muted-background;
$card-default-background: $global-muted-background;
$card-default-color: $global-color;
$card-default-title-color: $global-emphasis-color;
$card-default-hover-background: darken($card-default-background, 5%);
$card-primary-background: $global-primary-background;
$card-primary-color: $global-inverse-color;
$card-primary-title-color: $card-primary-color;
$card-primary-hover-background: darken($card-primary-background, 5%);
$card-primary-color-mode: light;
$card-secondary-background: $global-secondary-background;
$card-secondary-color: $global-inverse-color;
$card-secondary-title-color: $card-secondary-color;
$card-secondary-hover-background: darken($card-secondary-background, 5%);
$card-secondary-color-mode: light;
$card-small-body-padding-horizontal: $global-margin;
$card-small-body-padding-vertical: $global-margin;
$card-small-header-padding-horizontal: $global-margin;
$card-small-header-padding-vertical: round(calc($global-margin / 1.5));
$card-small-footer-padding-horizontal: $global-margin;
$card-small-footer-padding-vertical: round(calc($global-margin / 1.5));
$global-large-gutter: 70px;
$card-large-body-padding-horizontal-l: $global-large-gutter;
$card-large-body-padding-vertical-l: $global-large-gutter;
$card-large-header-padding-horizontal-l: $global-large-gutter;
$card-large-header-padding-vertical-l: round(calc($global-large-gutter / 2));
$card-large-footer-padding-horizontal-l: $global-large-gutter;
$card-large-footer-padding-vertical-l: round(calc($global-large-gutter / 2));
$close-color: $global-muted-color;
$close-hover-color: $global-color;
$inverse-close-color: $inverse-global-muted-color;
$inverse-close-hover-color: $inverse-global-color;
$column-gutter: $global-gutter;
$column-gutter-l: $global-medium-gutter;
$column-divider-rule-color: $global-border;
$column-divider-rule-width: 1px;
$inverse-column-divider-rule-color: $inverse-global-border;
$comment-header-margin-bottom: $global-margin;
$comment-title-font-size: $global-medium-font-size;
$comment-title-line-height: 1.4;
$comment-meta-font-size: $global-small-font-size;
$comment-meta-line-height: 1.4;
$comment-meta-color: $global-muted-color;
$comment-list-margin-top: $global-large-margin;
$comment-list-padding-left: 30px;
$comment-list-padding-left-m: 100px;
$container-max-width: 1200px;
$container-xsmall-max-width: 750px;
$container-small-max-width: 900px;
$container-large-max-width: 1600px;
$container-padding-horizontal: 15px;
$container-padding-horizontal-s: $global-gutter;
$container-padding-horizontal-m: $global-medium-gutter;
$countdown-item-line-height: 70px;
$countdown-number-font-size: 2rem;
$countdown-number-font-size-s: 4rem;
$countdown-number-font-size-m: 6rem;
$countdown-separator-font-size: 1rem;
$countdown-separator-font-size-s: 2rem;
$countdown-separator-font-size-m: 3rem;
$description-list-term-color: $global-emphasis-color;
$description-list-term-margin-top: $global-margin;
$description-list-divider-term-margin-top: $global-margin;
$description-list-divider-term-border-width: $global-border-width;
$description-list-divider-term-border: $global-border;
$divider-margin-vertical: $global-margin;
$divider-icon-width: 50px;
$divider-icon-height: 20px;
$divider-icon-color: $global-border;
$divider-icon-line-top: 50%;
$divider-icon-line-width: 100%;
$divider-icon-line-border-width: $global-border-width;
$divider-icon-line-border: $global-border;
$internal-divider-icon-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A";
$divider-small-width: 100px;
$divider-small-border-width: $global-border-width;
$divider-small-border: $global-border;
$divider-vertical-height: 100px;
$divider-vertical-border-width: $global-border-width;
$divider-vertical-border: $global-border;
$inverse-divider-icon-color: $inverse-global-border;
$inverse-divider-icon-line-border: $inverse-global-border;
$inverse-divider-small-border: $inverse-global-border;
$inverse-divider-vertical-border: $inverse-global-border;
$dotnav-margin-horizontal: 12px;
$dotnav-margin-vertical: $dotnav-margin-horizontal;
$dotnav-item-width: 10px;
$dotnav-item-height: $dotnav-item-width;
$dotnav-item-border-radius: 50%;
$dotnav-item-background: rgba($global-color, 0.2);
$dotnav-item-hover-background: rgba($global-color, 0.6);
$dotnav-item-onclick-background: rgba($global-color, 0.2);
$dotnav-item-active-background: rgba($global-color, 0.6);
$inverse-dotnav-item-background: rgba($inverse-global-color, 0.5);
$inverse-dotnav-item-hover-background: rgba($inverse-global-color, 0.9);
$inverse-dotnav-item-onclick-background: rgba($inverse-global-color, 0.5);
$inverse-dotnav-item-active-background: rgba($inverse-global-color, 0.9);
$global-z-index: 1000;
$drop-z-index: $global-z-index + 20;
$drop-width: 300px;
$drop-margin: $global-margin;
$dropdown-z-index: $global-z-index + 20;
$dropdown-min-width: 200px;
$dropdown-padding: 15px;
$dropdown-background: $global-muted-background;
$dropdown-color: $global-color;
$dropdown-margin: $global-small-margin;
$dropdown-nav-item-color: $global-muted-color;
$dropdown-nav-item-hover-color: $global-color;
$dropdown-nav-header-color: $global-emphasis-color;
$dropdown-nav-divider-border-width: $global-border-width;
$dropdown-nav-divider-border: $global-border;
$dropdown-nav-sublist-item-color: $global-muted-color;
$dropdown-nav-sublist-item-hover-color: $global-color;
$form-range-thumb-height: 15px;
$form-range-thumb-border-radius: 500px;
$form-range-thumb-background: $global-color;
$form-range-track-height: 3px;
$form-range-track-background: darken($global-muted-background, 5%);
$form-range-track-focus-background: darken($global-muted-background, 15%);
$form-height: $global-control-height;
$form-line-height: $form-height;
$form-padding-horizontal: 10px;
$form-padding-vertical: 4px;
$form-background: $global-muted-background;
$form-color: $global-color;
$form-focus-background: $global-muted-background;
$form-focus-color: $global-color;
$form-disabled-background: $global-muted-background;
$form-disabled-color: $global-muted-color;
$form-placeholder-color: $global-muted-color;
$form-small-height: $global-control-small-height;
$form-small-padding-horizontal: 8px;
$form-small-line-height: $form-small-height;
$form-small-font-size: $global-small-font-size;
$form-large-height: $global-control-large-height;
$form-large-padding-horizontal: 12px;
$form-large-line-height: $form-large-height;
$form-large-font-size: $global-medium-font-size;
$form-danger-color: $global-danger-background;
$form-success-color: $global-success-background;
$form-width-xsmall: 50px;
$form-width-small: 130px;
$form-width-medium: 200px;
$form-width-large: 500px;
$form-select-padding-right: 20px;
$form-select-icon-color: $global-color;
$form-select-option-color: #444;
$form-select-disabled-icon-color: $global-muted-color;
$form-radio-size: 16px;
$form-radio-margin-top: -4px;
$form-radio-background: darken($global-muted-background, 5%);
$form-radio-checked-background: $global-primary-background;
$form-radio-checked-icon-color: $global-inverse-color;
$form-radio-checked-focus-background: darken($global-primary-background, 10%);
$form-radio-disabled-background: $global-muted-background;
$form-radio-disabled-icon-color: $global-muted-color;
$form-legend-font-size: $global-large-font-size;
$form-legend-line-height: 1.4;
$form-stacked-margin-bottom: $global-small-margin;
$form-horizontal-label-width: 200px;
$form-horizontal-label-margin-top: 7px;
$form-horizontal-controls-margin-left: 215px;
$form-horizontal-controls-text-padding-top: 7px;
$form-icon-width: $form-height;
$form-icon-color: $global-muted-color;
$form-icon-hover-color: $global-color;
$internal-form-select-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A";
$internal-form-radio-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22#000%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E";
$internal-form-checkbox-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A";
$internal-form-checkbox-indeterminate-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E";
$inverse-global-muted-background: rgba($global-inverse-color, 0.1);
$inverse-form-background: $inverse-global-muted-background;
$inverse-form-color: $inverse-global-color;
$inverse-form-focus-background: $inverse-global-muted-background;
$inverse-form-focus-color: $inverse-global-color;
$inverse-form-placeholder-color: $inverse-global-muted-color;
$inverse-form-select-icon-color: $inverse-global-color;
$inverse-form-radio-background: darken($inverse-global-muted-background, 5%);
$inverse-form-radio-checked-background: $inverse-global-primary-background;
$inverse-form-radio-checked-icon-color: $inverse-global-inverse-color;
$inverse-form-radio-checked-focus-background: darken($inverse-global-primary-background, 10%);
$inverse-form-icon-color: $inverse-global-muted-color;
$inverse-form-icon-hover-color: $inverse-global-color;
$grid-gutter-horizontal: $global-gutter;
$grid-gutter-vertical: $grid-gutter-horizontal;
$grid-gutter-horizontal-l: $global-medium-gutter;
$grid-gutter-vertical-l: $grid-gutter-horizontal-l;
$grid-small-gutter-horizontal: $global-small-gutter;
$grid-small-gutter-vertical: $grid-small-gutter-horizontal;
$grid-medium-gutter-horizontal: $global-gutter;
$grid-medium-gutter-vertical: $grid-medium-gutter-horizontal;
$grid-large-gutter-horizontal: $global-medium-gutter;
$grid-large-gutter-vertical: $grid-large-gutter-horizontal;
$grid-large-gutter-horizontal-l: $global-large-gutter;
$grid-large-gutter-vertical-l: $grid-large-gutter-horizontal-l;
$grid-divider-border-width: 3px;
$grid-divider-border: $global-border;
$inverse-grid-divider-border: $inverse-global-border;
$heading-primary-font-size-l: 3.75rem;
$heading-primary-line-height-l: 1.1;
$heading-primary-font-size-m: $heading-primary-font-size-l * 0.9;
$heading-primary-font-size: $heading-primary-font-size-l * 0.8;
$heading-primary-line-height: 1.2;
$heading-hero-font-size-l: 8rem;
$heading-hero-line-height-l: 1;
$heading-hero-font-size-m: $heading-hero-font-size-l * 0.75;
$heading-hero-line-height-m: 1;
$heading-hero-font-size: $heading-hero-font-size-l * 0.5;
$heading-hero-line-height: 1.1;
$heading-divider-padding-bottom: 10px;
$heading-divider-border-width: $global-border-width;
$heading-divider-border: $global-border;
$heading-bullet-top: unquote("calc(-0.1 * 1em)");
$heading-bullet-height: 0.9em;
$heading-bullet-margin-right: 10px;
$heading-bullet-border-width: 5px;
$heading-bullet-border: $global-border;
$heading-line-top: 50%;
$heading-line-border-width: $global-border-width;
$heading-line-height: $heading-line-border-width;
$heading-line-width: 2000px;
$heading-line-border: $global-border;
$heading-line-margin-horizontal: 0.6em;
$inverse-heading-divider-border: $inverse-global-border;
$inverse-heading-bullet-border: $inverse-global-border;
$inverse-heading-line-border: $inverse-global-border;
$height-small-height: 150px;
$height-medium-height: 300px;
$height-large-height: 450px;
$icon-image-size: 20px;
$icon-link-color: $global-muted-color;
$icon-link-hover-color: $global-color;
$icon-link-active-color: darken($global-color, 5%);
$icon-button-size: 36px;
$icon-button-border-radius: 500px;
$icon-button-background: $global-muted-background;
$icon-button-color: $global-muted-color;
$icon-button-hover-background: darken($icon-button-background, 5%);
$icon-button-hover-color: $global-color;
$icon-button-active-background: darken($icon-button-background, 10%);
$icon-button-active-color: $global-color;
$inverse-icon-link-color: $inverse-global-muted-color;
$inverse-icon-link-hover-color: $inverse-global-color;
$inverse-icon-link-active-color: $inverse-global-color;
$inverse-icon-button-background: $inverse-global-muted-background;
$inverse-icon-button-color: $inverse-global-muted-color;
$inverse-icon-button-hover-background: darken($inverse-icon-button-background, 5%);
$inverse-icon-button-hover-color: $inverse-global-color;
$inverse-icon-button-active-background: darken($inverse-icon-button-background, 10%);
$inverse-icon-button-active-color: $inverse-global-color;
$iconnav-margin-horizontal: $global-small-margin;
$iconnav-margin-vertical: $iconnav-margin-horizontal;
$iconnav-item-color: $global-muted-color;
$iconnav-item-hover-color: $global-color;
$iconnav-item-active-color: $global-color;
$inverse-iconnav-item-color: $inverse-global-muted-color;
$inverse-iconnav-item-hover-color: $inverse-global-color;
$inverse-iconnav-item-active-color: $inverse-global-color;
$inverse-global-color-mode: light;
$label-padding-vertical: 0;
$label-padding-horizontal: $global-small-margin;
$label-background: $global-primary-background;
$label-line-height: $global-line-height;
$label-font-size: $global-small-font-size;
$label-color: $global-inverse-color;
$label-success-background: $global-success-background;
$label-success-color: $global-inverse-color;
$label-warning-background: $global-warning-background;
$label-warning-color: $global-inverse-color;
$label-danger-background: $global-danger-background;
$label-danger-color: $global-inverse-color;
$inverse-label-background: $inverse-global-primary-background;
$inverse-label-color: $inverse-global-inverse-color;
$leader-fill-content: unquote(".");
$leader-fill-margin-left: $global-small-gutter;
$lightbox-z-index: $global-z-index + 10;
$lightbox-background: #000;
$lightbox-item-color: rgba(255, 255, 255, 0.7);
$lightbox-item-max-width: 100vw;
$lightbox-item-max-height: 100vh;
$lightbox-toolbar-padding-vertical: 10px;
$lightbox-toolbar-padding-horizontal: 10px;
$lightbox-toolbar-background: rgba(0, 0, 0, 0.3);
$lightbox-toolbar-color: rgba(255, 255, 255, 0.7);
$lightbox-toolbar-icon-padding: 5px;
$lightbox-toolbar-icon-color: rgba(255, 255, 255, 0.7);
$lightbox-toolbar-icon-hover-color: #fff;
$lightbox-button-size: 50px;
$lightbox-button-background: $lightbox-toolbar-background;
$lightbox-button-color: rgba(255, 255, 255, 0.7);
$lightbox-button-hover-color: #fff;
$link-muted-color: $global-muted-color;
$link-muted-hover-color: $global-color;
$link-text-hover-color: $global-muted-color;
$link-heading-hover-color: $global-primary-background;
$link-heading-hover-text-decoration: none;
$inverse-link-muted-color: $inverse-global-muted-color;
$inverse-link-muted-hover-color: $inverse-global-color;
$inverse-link-text-hover-color: $inverse-global-muted-color;
$inverse-link-heading-hover-color: $inverse-global-primary-background;
$list-margin-top: $global-small-margin;
$list-nested-padding-left: $global-gutter;
$list-divider-margin-top: $global-small-margin;
$list-divider-border-width: $global-border-width;
$list-divider-border: $global-border;
$list-striped-padding-vertical: $global-small-margin;
$list-striped-padding-horizontal: $global-small-margin;
$list-striped-background: $global-muted-background;
$list-bullet-width: ($global-line-height * 1em);
$list-bullet-height: $list-bullet-width;
$list-bullet-margin-right: $global-small-margin;
$list-bullet-icon-color: $global-color;
$list-large-margin-top: $global-margin;
$list-large-divider-margin-top: $global-margin;
$list-large-striped-padding-vertical: $global-margin;
$list-large-striped-padding-horizontal: $global-small-margin;
$internal-list-bullet-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22#000%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E";
$inverse-list-divider-border: $inverse-global-border;
$inverse-list-striped-background: $inverse-global-muted-background;
$inverse-list-bullet-icon-color: $inverse-global-color;
$margin-margin: $global-margin;
$margin-small-margin: $global-small-margin;
$margin-medium-margin: $global-medium-margin;
$margin-large-margin: $global-medium-margin;
$margin-large-margin-l: $global-large-margin;
$margin-xlarge-margin: $global-large-margin;
$global-xlarge-margin: 140px;
$margin-xlarge-margin-l: $global-xlarge-margin;
$marker-padding: 5px;
$marker-background: $global-secondary-background;
$marker-color: $global-inverse-color;
$marker-hover-color: $global-inverse-color;
$inverse-marker-background: $global-muted-background;
$inverse-marker-color: $global-color;
$inverse-marker-hover-color: $global-color;
$modal-z-index: $global-z-index + 10;
$modal-background: rgba(0, 0, 0, 0.6);
$modal-padding-horizontal: 15px;
$modal-padding-horizontal-s: $global-gutter;
$modal-padding-horizontal-m: $global-medium-gutter;
$modal-padding-vertical: $modal-padding-horizontal;
$modal-padding-vertical-s: 50px;
$modal-dialog-width: 600px;
$modal-dialog-background: $global-background;
$modal-container-width: 1200px;
$modal-body-padding-horizontal: $global-gutter;
$modal-body-padding-vertical: $global-gutter;
$modal-header-padding-horizontal: $global-gutter;
$modal-header-padding-vertical: (calc($modal-header-padding-horizontal / 2));
$modal-header-background: $global-muted-background;
$modal-footer-padding-horizontal: $global-gutter;
$modal-footer-padding-vertical: (calc($modal-footer-padding-horizontal / 2));
$modal-footer-background: $global-muted-background;
$modal-title-font-size: $global-xlarge-font-size;
$modal-title-line-height: 1.3;
$modal-close-position: $global-small-margin;
$modal-close-padding: 5px;
$modal-close-outside-position: 0;
$modal-close-outside-translate: 100%;
$modal-close-outside-color: lighten($global-inverse-color, 20%);
$modal-close-outside-hover-color: $global-inverse-color;
$nav-item-padding-vertical: 5px;
$nav-item-padding-horizontal: 0;
$nav-sublist-padding-vertical: 5px;
$nav-sublist-padding-left: 15px;
$nav-sublist-deeper-padding-left: 15px;
$nav-sublist-item-padding-vertical: 2px;
$nav-parent-icon-width: ($global-line-height * 1em);
$nav-parent-icon-height: $nav-parent-icon-width;
$nav-parent-icon-color: $global-color;
$nav-header-padding-vertical: $nav-item-padding-vertical;
$nav-header-padding-horizontal: $nav-item-padding-horizontal;
$nav-header-font-size: $global-small-font-size;
$nav-header-text-transform: uppercase;
$nav-header-margin-top: $global-margin;
$nav-divider-margin-vertical: 5px;
$nav-divider-margin-horizontal: 0;
$nav-default-item-color: $global-muted-color;
$nav-default-item-hover-color: $global-link-color;
$nav-default-item-active-color: $global-emphasis-color;
$nav-default-header-color: $global-emphasis-color;
$nav-default-divider-border-width: $global-border-width;
$nav-default-divider-border: $global-border;
$nav-default-sublist-item-color: $global-muted-color;
$nav-default-sublist-item-hover-color: $global-color;
$nav-default-sublist-item-active-color: $global-emphasis-color;
$nav-primary-item-font-size: $global-large-font-size;
$nav-primary-item-line-height: $global-line-height;
$nav-primary-item-color: $global-muted-color;
$nav-primary-item-hover-color: $global-color;
$nav-primary-item-active-color: $global-emphasis-color;
$nav-primary-header-color: $global-emphasis-color;
$nav-primary-divider-border-width: $global-border-width;
$nav-primary-divider-border: $global-border;
$nav-primary-sublist-item-color: $global-muted-color;
$nav-primary-sublist-item-hover-color: $global-color;
$nav-primary-sublist-item-active-color: $global-emphasis-color;
$internal-nav-parent-close-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E";
$internal-nav-parent-open-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E";
$inverse-nav-parent-icon-color: $inverse-global-color;
$inverse-nav-default-item-color: $inverse-global-muted-color;
$inverse-nav-default-item-hover-color: $inverse-global-color;
$inverse-nav-default-item-active-color: $inverse-global-emphasis-color;
$inverse-nav-default-header-color: $inverse-global-emphasis-color;
$inverse-nav-default-divider-border: $inverse-global-border;
$inverse-nav-default-sublist-item-color: $inverse-global-muted-color;
$inverse-nav-default-sublist-item-hover-color: $inverse-global-color;
$inverse-nav-default-sublist-item-active-color: $inverse-global-emphasis-color;
$inverse-nav-primary-item-color: $inverse-global-muted-color;
$inverse-nav-primary-item-hover-color: $inverse-global-color;
$inverse-nav-primary-item-active-color: $inverse-global-emphasis-color;
$inverse-nav-primary-header-color: $inverse-global-emphasis-color;
$inverse-nav-primary-divider-border: $inverse-global-border;
$inverse-nav-primary-sublist-item-color: $inverse-global-muted-color;
$inverse-nav-primary-sublist-item-hover-color: $inverse-global-color;
$inverse-nav-primary-sublist-item-active-color: $inverse-global-emphasis-color;
$navbar-background: #fff;
$navbar-color-mode: none;
$navbar-nav-item-height: 80px;
$navbar-nav-item-padding-horizontal: 15px;
$navbar-nav-item-color: $global-muted-color;
$navbar-nav-item-font-size: 20px;
$navbar-nav-item-font-family: $global-font-cabin;
$navbar-nav-item-hover-color: $global-color;
$navbar-nav-item-onclick-color: $global-link-color;
$navbar-nav-item-active-color: $global-link-color;
$navbar-item-color: $global-color;
$navbar-toggle-color: $global-muted-color;
$navbar-toggle-hover-color: $global-color;
$navbar-subtitle-font-size: $global-small-font-size;
$navbar-dropdown-z-index: $global-z-index + 20;
$navbar-dropdown-width: 200px;
$navbar-dropdown-margin: 0;
$navbar-dropdown-padding: 15px;
$navbar-dropdown-background: $global-muted-background;
$navbar-dropdown-color: $global-color;
$navbar-dropdown-grid-gutter-horizontal: $global-gutter;
$navbar-dropdown-grid-gutter-vertical: $navbar-dropdown-grid-gutter-horizontal;
$navbar-dropdown-dropbar-margin-top: 0;
$navbar-dropdown-dropbar-margin-bottom: $navbar-dropdown-dropbar-margin-top;
$navbar-dropdown-nav-item-color: $global-muted-color;
$navbar-dropdown-nav-item-hover-color: $global-color;
$navbar-dropdown-nav-item-active-color: $global-emphasis-color;
$navbar-dropdown-nav-header-color: $global-emphasis-color;
$navbar-dropdown-nav-divider-border-width: $global-border-width;
$navbar-dropdown-nav-divider-border: $global-border;
$navbar-dropdown-nav-sublist-item-color: $global-muted-color;
$navbar-dropdown-nav-sublist-item-hover-color: $global-color;
$navbar-dropdown-nav-sublist-item-active-color: $global-emphasis-color;
$navbar-dropbar-background: $navbar-dropdown-background;
$navbar-dropbar-z-index: $global-z-index - 20;
$inverse-navbar-nav-item-color: $inverse-global-muted-color;
$inverse-navbar-nav-item-hover-color: $inverse-global-color;
$inverse-navbar-nav-item-onclick-color: $inverse-global-emphasis-color;
$inverse-navbar-nav-item-active-color: $inverse-global-emphasis-color;
$inverse-navbar-item-color: $inverse-global-color;
$inverse-navbar-toggle-color: $inverse-global-muted-color;
$inverse-navbar-toggle-hover-color: $inverse-global-color;
$notification-position: 10px;
$notification-z-index: $global-z-index + 40;
$notification-width: 350px;
$notification-message-margin-top: 10px;
$notification-message-padding: $global-small-gutter;
$notification-message-background: $global-muted-background;
$notification-message-color: $global-color;
$notification-message-font-size: $global-medium-font-size;
$notification-message-line-height: 1.4;
$notification-close-top: $notification-message-padding + 5px;
$notification-close-right: $notification-message-padding;
$notification-message-primary-color: $global-primary-background;
$notification-message-success-color: $global-success-background;
$notification-message-warning-color: $global-warning-background;
$notification-message-danger-color: $global-danger-background;
$offcanvas-z-index: $global-z-index;
$offcanvas-bar-width: 270px;
$offcanvas-bar-padding-vertical: $global-margin;
$offcanvas-bar-padding-horizontal: $global-margin;
$offcanvas-bar-background: #fff;
$offcanvas-bar-color-mode: dark;
$offcanvas-bar-width-m: 350px;
$offcanvas-bar-padding-vertical-m: $global-medium-gutter;
$offcanvas-bar-padding-horizontal-m: $global-medium-gutter;
$offcanvas-close-position: 10px;
$offcanvas-close-padding: 5px;
$offcanvas-overlay-background: rgba(0, 0, 0, 0.1);
$overlay-padding-horizontal: $global-gutter;
$overlay-padding-vertical: $global-gutter;
$overlay-default-background: rgba($global-background, 0.8);
$overlay-primary-background: rgba($global-secondary-background, 0.8);
$overlay-primary-color-mode: light;
$padding-padding: $global-gutter;
$padding-padding-l: $global-medium-gutter;
$padding-small-padding: $global-small-gutter;
$padding-large-padding: $global-gutter;
$padding-large-padding-l: $global-large-gutter;
$pagination-margin-horizontal: 20px;
$pagination-item-color: $global-muted-color;
$pagination-item-hover-color: $global-color;
$pagination-item-hover-text-decoration: none;
$pagination-item-active-color: $global-color;
$pagination-item-disabled-color: $global-muted-color;
$inverse-pagination-item-color: $inverse-global-muted-color;
$inverse-pagination-item-hover-color: $inverse-global-color;
$inverse-pagination-item-active-color: $inverse-global-color;
$inverse-pagination-item-disabled-color: $inverse-global-muted-color;
$placeholder-margin-vertical: $global-margin;
$placeholder-padding-vertical: $global-gutter;
$placeholder-padding-horizontal: $global-gutter;
$placeholder-background: $global-muted-background;
$position-small-margin: $global-small-gutter;
$position-medium-margin: $global-gutter;
$position-large-margin: $global-gutter;
$position-large-margin-l: 50px;
$progress-height: 15px;
$progress-margin-vertical: $global-margin;
$progress-background: $global-muted-background;
$progress-bar-background: $global-primary-background;
$search-color: $global-color;
$search-placeholder-color: $global-muted-color;
$search-icon-color: $global-muted-color;
$search-default-width: 180px;
$search-default-height: $global-control-height;
$search-default-padding-horizontal: 6px;
$search-default-background: $global-muted-background;
$search-default-focus-background: $search-default-background;
$search-default-icon-width: $global-control-height;
$search-navbar-width: 400px;
$search-navbar-height: 40px;
$search-navbar-background: transparent;
$search-navbar-font-size: $global-large-font-size;
$search-navbar-icon-width: 40px;
$search-large-width: 500px;
$search-large-height: 80px;
$search-large-background: transparent;
$search-large-font-size: $global-xxlarge-font-size;
$search-large-icon-width: 80px;
$search-toggle-color: $global-muted-color;
$search-toggle-hover-color: $global-color;
$inverse-search-color: $inverse-global-color;
$inverse-search-placeholder-color: $inverse-global-muted-color;
$inverse-search-icon-color: $inverse-global-muted-color;
$inverse-search-default-background: $inverse-global-muted-background;
$inverse-search-default-focus-background: $inverse-search-default-background;
$inverse-search-navbar-background: transparent;
$inverse-search-large-background: transparent;
$inverse-search-toggle-color: $inverse-global-muted-color;
$inverse-search-toggle-hover-color: $inverse-global-color;
$section-padding-vertical: $global-medium-margin;
$section-padding-vertical-m: $global-large-margin;
$section-xsmall-padding-vertical: $global-margin;
$section-small-padding-vertical: $global-medium-margin;
$section-large-padding-vertical: $global-large-margin;
$section-large-padding-vertical-m: $global-xlarge-margin;
$section-xlarge-padding-vertical: $global-xlarge-margin;
$section-xlarge-padding-vertical-m: ($global-large-margin + $global-xlarge-margin);
$section-default-background: $global-background;
$section-muted-background: $global-muted-background;
$section-primary-background: $global-primary-background;
$section-primary-color-mode: dark;
$section-secondary-background: $global-secondary-background;
$section-secondary-color-mode: light;
$slidenav-padding-vertical: 5px;
$slidenav-padding-horizontal: 10px;
$slidenav-color: rgba($global-color, 0.5);
$slidenav-hover-color: rgba($global-color, 0.9);
$slidenav-active-color: rgba($global-color, 0.5);
$slidenav-large-padding-vertical: 10px;
$slidenav-large-padding-horizontal: $slidenav-large-padding-vertical;
$inverse-slidenav-color: rgba($inverse-global-color, 0.7);
$inverse-slidenav-hover-color: rgba($inverse-global-color, 0.95);
$inverse-slidenav-active-color: rgba($inverse-global-color, 0.7);
$sortable-dragged-z-index: $global-z-index + 50;
$sortable-placeholder-opacity: 0;
$sortable-empty-height: 50px;
$spinner-size: 30px;
$spinner-stroke-width: 1;
$spinner-radius: floor(($spinner-size - $spinner-stroke-width) / 2);
$spinner-circumference: round(2 * 3.141 * $spinner-radius);
$spinner-duration: 1.4s;
$sticky-z-index: $global-z-index - 20;
$sticky-animation-duration: 0.2s;
$sticky-reverse-animation-duration: 0.2s;
$subnav-margin-horizontal: 20px;
$subnav-item-color: $global-muted-color;
$subnav-item-hover-color: $global-color;
$subnav-item-hover-text-decoration: none;
$subnav-item-active-color: $global-emphasis-color;
$subnav-divider-margin-horizontal: $subnav-margin-horizontal;
$subnav-divider-border-height: 1.5em;
$subnav-divider-border-width: $global-border-width;
$subnav-divider-border: $global-border;
$subnav-pill-item-padding-vertical: 5px;
$subnav-pill-item-padding-horizontal: 10px;
$subnav-pill-item-background: transparent;
$subnav-pill-item-color: $subnav-item-color;
$subnav-pill-item-hover-background: $global-muted-background;
$subnav-pill-item-hover-color: $global-color;
$subnav-pill-item-onclick-background: $subnav-pill-item-hover-background;
$subnav-pill-item-onclick-color: $subnav-pill-item-hover-color;
$subnav-pill-item-active-background: $global-primary-background;
$subnav-pill-item-active-color: $global-inverse-color;
$subnav-item-disabled-color: $global-muted-color;
$inverse-subnav-item-color: $inverse-global-muted-color;
$inverse-subnav-item-hover-color: $inverse-global-color;
$inverse-subnav-item-active-color: $inverse-global-emphasis-color;
$inverse-subnav-divider-border: $inverse-global-border;
$inverse-subnav-pill-item-background: transparent;
$inverse-subnav-pill-item-color: $inverse-global-muted-color;
$inverse-subnav-pill-item-hover-background: $inverse-global-muted-background;
$inverse-subnav-pill-item-hover-color: $inverse-global-color;
$inverse-subnav-pill-item-onclick-background: $inverse-subnav-pill-item-hover-background;
$inverse-subnav-pill-item-onclick-color: $inverse-subnav-pill-item-hover-color;
$inverse-subnav-pill-item-active-background: $inverse-global-primary-background;
$inverse-subnav-pill-item-active-color: $inverse-global-inverse-color;
$inverse-subnav-item-disabled-color: $inverse-global-muted-color;
$tab-margin-horizontal: 20px;
$tab-item-padding-horizontal: 10px;
$tab-item-padding-vertical: 5px;
$tab-item-color: $global-muted-color;
$tab-item-hover-color: $global-color;
$tab-item-hover-text-decoration: none;
$tab-item-active-color: $global-emphasis-color;
$tab-item-disabled-color: $global-muted-color;
$inverse-tab-item-color: $inverse-global-muted-color;
$inverse-tab-item-hover-color: $inverse-global-color;
$inverse-tab-item-active-color: $inverse-global-emphasis-color;
$inverse-tab-item-disabled-color: $inverse-global-muted-color;
$table-margin-vertical: $global-margin;
$table-cell-padding-vertical: 16px;
$table-cell-padding-horizontal: 12px;
$table-header-cell-font-size: $global-font-size;
$table-header-cell-font-weight: bold;
$table-header-cell-color: $global-color;
$table-footer-font-size: $global-small-font-size;
$table-caption-font-size: $global-small-font-size;
$table-caption-color: $global-muted-color;
$table-row-active-background: #ffd;
$table-divider-border-width: $global-border-width;
$table-divider-border: $global-border;
$table-striped-row-background: $global-muted-background;
$table-hover-row-background: $table-row-active-background;
$table-small-cell-padding-vertical: 10px;
$table-small-cell-padding-horizontal: 12px;
$table-large-cell-padding-vertical: 22px;
$table-large-cell-padding-horizontal: 12px;
$table-expand-min-width: 150px;
$inverse-table-header-cell-color: $inverse-global-color;
$inverse-table-caption-color: $inverse-global-muted-color;
$inverse-table-row-active-background: fade-out($inverse-global-muted-background, 0.02);
$inverse-table-divider-border: $inverse-global-border;
$inverse-table-striped-row-background: $inverse-global-muted-background;
$inverse-table-hover-row-background: $inverse-table-row-active-background;
$text-lead-font-size: $global-large-font-size;
$text-lead-line-height: 1.5;
$text-lead-color: $global-emphasis-color;
$text-meta-font-size: $global-small-font-size;
$text-meta-line-height: 1.4;
$text-meta-color: $global-muted-color;
$text-small-font-size: $global-small-font-size;
$text-small-line-height: 1.5;
$text-large-font-size: $global-large-font-size;
$text-large-line-height: 1.5;
$text-bold-font-weight: bolder;
$text-muted-color: $global-muted-color;
$text-emphasis-color: $global-emphasis-color;
$text-primary-color: $global-primary-background;
$text-success-color: $global-success-background;
$text-warning-color: $global-warning-background;
$text-danger-color: $global-danger-background;
$text-background-color: $global-primary-background;
$inverse-text-lead-color: $inverse-global-color;
$inverse-text-meta-color: $inverse-global-muted-color;
$inverse-text-muted-color: $inverse-global-muted-color;
$inverse-text-emphasis-color: $inverse-global-emphasis-color;
$inverse-text-primary-color: $inverse-global-color;
$thumbnav-margin-horizontal: 15px;
$thumbnav-margin-vertical: $thumbnav-margin-horizontal;
$tile-padding-horizontal: 15px;
$tile-padding-horizontal-s: $global-gutter;
$tile-padding-horizontal-m: $global-medium-gutter;
$tile-padding-vertical: $global-medium-margin;
$tile-padding-vertical-m: $global-large-margin;
$tile-xsmall-padding-vertical: $global-margin;
$tile-small-padding-vertical: $global-medium-margin;
$tile-large-padding-vertical: $global-large-margin;
$tile-large-padding-vertical-m: $global-xlarge-margin;
$tile-xlarge-padding-vertical: $global-xlarge-margin;
$tile-xlarge-padding-vertical-m: ($global-large-margin + $global-xlarge-margin);
$tile-default-background: $global-background;
$tile-muted-background: $global-muted-background;
$tile-primary-background: $global-primary-background;
$tile-primary-color-mode: light;
$tile-secondary-background: $global-secondary-background;
$tile-secondary-color-mode: light;
$tooltip-z-index: $global-z-index + 30;
$tooltip-max-width: 200px;
$tooltip-padding-vertical: 3px;
$tooltip-padding-horizontal: 6px;
$tooltip-background: #666;
$tooltip-border-radius: 2px;
$tooltip-color: $global-inverse-color;
$tooltip-font-size: 12px;
$tooltip-margin: 10px;
$totop-padding: 5px;
$totop-color: $global-muted-color;
$totop-hover-color: $global-color;
$totop-active-color: $global-emphasis-color;
$inverse-totop-color: $inverse-global-muted-color;
$inverse-totop-hover-color: $inverse-global-color;
$inverse-totop-active-color: $inverse-global-emphasis-color;
$transition-duration: 0.3s;
$transition-scale: 1.1;
$transition-slide-small-translate: 10px;
$transition-slide-medium-translate: 50px;
$transition-slow-duration: 0.7s;
$panel-scrollable-height: 170px;
$panel-scrollable-padding: 10px;
$panel-scrollable-border-width: $global-border-width;
$panel-scrollable-border: $global-border;
$border-rounded-border-radius: 5px;
$box-shadow-duration: 0.1s;
$box-shadow-bottom-height: 30px;
$box-shadow-bottom-border-radius: 100%;
$box-shadow-bottom-background: #444;
$box-shadow-bottom-blur: 20px;
$dropcap-margin-right: 10px;
$dropcap-font-size: (($global-line-height * 3) * 1em);
$logo-font-size: $global-large-font-size;
$logo-font-family: $global-font-family;
$logo-color: $global-color;
$logo-hover-color: $global-color;
$dragover-box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);
$inverse-logo-color: $inverse-global-color;
$inverse-logo-hover-color: $inverse-global-color;
$breakpoint-small: 640px;
$breakpoint-medium: 960px;
$breakpoint-large: 1200px;
$breakpoint-xlarge: 1600px;
$breakpoint-xsmall-max: ($breakpoint-small - 1);
$breakpoint-small-max: ($breakpoint-medium - 1);
$breakpoint-medium-max: ($breakpoint-large - 1);
$breakpoint-large-max: ($breakpoint-xlarge - 1);
$global-small-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
$global-medium-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
$global-large-box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
$global-xlarge-box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
$width-small-width: 150px;
$width-medium-width: 300px;
$width-large-width: 450px;
$width-xlarge-width: 600px;
$width-xxlarge-width: 750px;

@import "~uikit/src/scss/mixins";

@mixin hook-base-h6() {
  font-family: $global-font-great;
  font-size: 45px;
}

@import "~uikit/src/scss/uikit";

[data-uk-header] {
  position: absolute;
  z-index: 980;
  left: 0;
  top: 0;
}

.cb-object-cover {
  object-fit: cover;
}

.uk-navbar-nav > li:hover > a {
  color: $global-link-color;
}

.uk-nav > li > .uk-active,
.uk-navbar-nav > li > .uk-active {
  color: $global-link-color;
}

.uk-nav {
  font-family: $global-font-cabin;
}
